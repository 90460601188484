import React from 'react'
import { graphql, Link } from "gatsby"
import tw, { styled } from 'twin.macro'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from "../components/Layout"
import Section from '../components/Section'
import ContactUs from '../components/ContactUs'
import ImageCards from '../components/ImageCards'

import Breadcrumbs from '../components/Breadcrumbs'

const Image = styled(GatsbyImage)`
    ${tw`
        w-full
    `}

    max-height: 350px;
    grid-column: full;

    @media (min-width: 1024px) {
        grid-column: standard;
    }
`

// This takes on the size of an h3 as per the custom global styles but every page needs to have an h1 for SEO optimization
const Title = styled.h1`
    ${tw`
        mt-10 font-bold w-full

        text-4xl sm:text-5xl
        leading-tight sm:leading-tight   
    `}
`

const ImageCarousel = styled.div`
    ${tw`
        overflow-x-auto
        w-full
        mt-8 
        flex
        items-stretch
    `}
    
    grid-column: full;

    // All code below is to make the overflow work for centered flex items
    > *:first-child {
        ${tw`
            ml-auto pl-8
        `}
    }

    > *:last-child {
        ${tw`
            mr-auto pr-8
        `}
    }
`

const ImageCardOuter = styled.div`
    ${tw`
        p-4 pb-10
    `}
`

const ImageCardInner = styled.div`
    ${tw`
        flex flex-col flex-shrink-0
        shadow-xl transition-shadow duration-200 
        overflow-hidden 
        rounded-2xl
        w-64 h-full
    `}
`

const ImgContainer = styled.div`
    ${tw`
        w-full
        bg-gray-400
        w-full h-52
    `}

    > svg, img {
        ${tw`
            object-cover
            h-full h-full
        `}
    }
`

const TextContainer = styled.div`
    ${tw`
        w-full p-4
        flex-grow
        flex flex-col
        text-center 
        justify-center
    `}

    > h6 {
        ${tw`
            font-semibold
            mb-2
        `}
    }
`

const BodyContainer = styled.div`
    ${tw`
        w-full
        grid gap-28
    `}

    @media (min-width: 1024px) {
        // grid-template-columns: 2fr 1fr;
        grid-template-columns: 1fr 22rem;        //try this one if the above gives problems
    }
`

const Description = styled.div`
    ${tw`
        order-2 lg:order-1
    `}

    & > *:first-child {
        ${tw`
            pt-0 
            mt-0
        `}
    }

    h3, h2 {
        ${tw`
            pt-12 pb-4
            font-bold
        `}
    }

    h6 {
        color: darkorange;
    }

    p {
        ${tw`
            py-4 px-0
        `}
    }

    a {
        ${tw`
            no-underline hover:underline
        `}

        color: darkorange;
    }

    ol, ul {
        ${tw`
            py-4 pl-7
        `}
    }

    ol {
        ${tw`
            list-decimal
        `}
    }

    ul {
        ${tw`
            list-disc
        `}
    }

    img {
        ${tw`
            object-contain 
            max-w-full
        `}
    }

    table {
        ${tw`
            border-collapse
        `}
    }

    th, td {
        ${tw`
            py-2 px-6
            border border-gray-300
        `}
    }

    blockquote {
        ${tw`
            mt-4
            pl-12
            border-l-2 border-gray-300
        `}
    }

    code {
      font-style: oblique;
    }
  
    iframe {
      ${tw`
        py-4
        w-full
      `}
    }
`

const SidePane = styled.div`
    ${tw`
        order-1 lg:order-2
    `}
`

const SpHeader = styled.h3`
    ${tw`
        font-bold
    `}
`

const SpSection = styled.div`
    ${tw`
        py-6
        border-b border-gray-300
    `}

    :first-child {
        ${tw`
            pt-0
        `}
    }

    :last-child {
        ${tw`
            border-none
        `}
    }
`

const SpSectionHeader = styled.div`
    ${tw`
        flex justify-between
        mb-4
    `}

    ${({ noMarginBottom }) => noMarginBottom && tw`     
        mb-0
    `}
`

const SpSectionTitle = styled.p`
    ${tw`
        uppercase font-semibold
        mr-6
    `}
`

const SpSectionCoTitle = styled.p`
    ${tw`
        break-words
        text-right
    `}
`

const SpImageItems = styled.div`
    ${tw`
        flex flex-wrap gap-4
        items-center justify-start
    `}

    img, svg {
        ${tw`
            w-10 h-auto
            filter grayscale
        `}
    }
`

const SpPillItems = styled.div`
    ${tw`
        flex flex-wrap justify-start gap-2
    `}
`

const SpPill = styled.span`
    ${tw`
        rounded-full 
        text-white
        py-1 px-3
        overflow-ellipsis
        overflow-hidden whitespace-nowrap
        inline-block
    `}

    max-width: calc(100vw - 5rem);
    background: darkorange;

    @media (min-width: 1024px) {
        max-width: 22rem;           // relates back to grid column template above
    }
    
`

const RelatedArticle = styled.div`
    ${tw`
        mb-8
    `}

    p {
        ${tw`
            mt-2
            text-gray-400
        `}
    }
`

const ArticleLink = styled(Link)`
    ${tw`
        underline hover:no-underline
    `}
`

const CardsSectionHeader = styled.h3`
    ${tw`
        font-bold
        mb-6
    `}
`

const CardsSectionLink = styled(Link)`
    ${tw`
        text-lg
        mt-8
        underline
    `}

    &:hover {
        text-decoration-color: darkorange;
    }
`

const SubPageOne = ({
    data: {
        markdownRemark: {
            html,
            frontmatter: {
                seo,
                title,
                headingImage,
                imageShowcase,
                sidePane,
                cardShowcase
            }
        },
        relatedBlogs: { edges: relatedArticles }
    },
    location
}) => {
    return (
        <Layout title={seo.title} description={seo.description}>
            <Section>
                <Image image={headingImage.childImageSharp.gatsbyImageData}
                    objectFit="cover"
                    objectPosition="50% 50%" />

                <Title>{title}</Title>

                {imageShowcase && (
                    <ImageCarousel>
                        {imageShowcase.data.map(({ image, text }, imgIdx) => (
                            <ImageCardOuter>
                                <ImageCardInner>
                                    <ImgContainer>
                                        <img
                                            src={image.relativePath ? `/img/${image.relativePath}` : image}
                                            alt={imageShowcase.hasText ? `Visual representation of ${text.title}` : `${title} carousel image ${imgIdx + 1}`}
                                        />
                                    </ImgContainer>
                                    {imageShowcase.hasText && (
                                        <TextContainer>
                                            <h6>{text.title}</h6>
                                            <p>{text.body}</p>
                                        </TextContainer>
                                    )}
                                </ImageCardInner>
                            </ImageCardOuter>
                        ))}
                    </ImageCarousel>
                )}
                <Breadcrumbs data={location.pathname.split("/")} />
            </Section>

            <Section>
                <BodyContainer>
                    <Description dangerouslySetInnerHTML={{ __html: html }} />
                    {sidePane && (
                        <SidePane>
                            <SpSection>
                                <SpHeader>
                                    {sidePane.header}
                                </SpHeader>
                            </SpSection>

                            {sidePane.imageItems && (
                                <SpSection>
                                    <SpSectionHeader>
                                        <SpSectionTitle>
                                            {sidePane.imageItems.title}
                                        </SpSectionTitle>
                                    </SpSectionHeader>
                                    <SpImageItems>
                                        {sidePane.imageItems.images.map(({ image, url, imgTitle }, spImgIdx) => (
                                            <Link to={url ? url : null}>
                                                <img
                                                    src={image.relativePath ? `/img/${image.relativePath}` : image}
                                                    alt={`${imgTitle} icon`}
                                                    key={spImgIdx}
                                                    title={imgTitle}
                                                />
                                            </Link>
                                        ))}
                                    </SpImageItems>
                                </SpSection>
                            )}

                            {sidePane.pillItems && (
                                <SpSection>
                                    <SpSectionHeader>
                                        <SpSectionTitle>
                                            {sidePane.pillItems.title}
                                        </SpSectionTitle>
                                    </SpSectionHeader>
                                    <SpPillItems>
                                        {sidePane.pillItems.pills.map(({ label, url }, pillIdx) => (
                                            <Link to={url} key={pillIdx}>
                                                <SpPill>
                                                    {label}
                                                </SpPill>
                                            </Link>
                                        ))}
                                    </SpPillItems>
                                </SpSection>
                            )}

                            {sidePane.infoPairs &&
                                sidePane.infoPairs.map(({ title, info }, infoPairIdx) => (
                                    <SpSection key={infoPairIdx}>
                                        <SpSectionHeader noMarginBottom>
                                            <SpSectionTitle>
                                                {title}
                                            </SpSectionTitle>
                                            <SpSectionCoTitle>{info}</SpSectionCoTitle>
                                        </SpSectionHeader>
                                    </SpSection>
                                ))}

                            {sidePane.includeRelatedBlogs &&
                                <SpSection>
                                    <SpSectionHeader>
                                        <SpSectionTitle>
                                            Related Articles
                                        </SpSectionTitle>
                                    </SpSectionHeader>
                                    {relatedArticles.length > 0 ?
                                        relatedArticles.map(({ node: { fields, frontmatter: { title, date } } }, articleIdx) => (
                                            <RelatedArticle>
                                                <ArticleLink to={fields.slug}>{title}</ArticleLink>
                                                <p>{date}</p>
                                            </RelatedArticle>
                                        )) : (
                                            <p>No related articles at the moment</p>
                                        )
                                    }
                                </SpSection>
                            }

                        </SidePane>
                    )}
                </BodyContainer>
            </Section>

            {cardShowcase && cardShowcase.display && (
                <Section>
                    <CardsSectionHeader>
                        {cardShowcase.title}
                    </CardsSectionHeader>
                    <ImageCards data={cardShowcase.cards} />
                    <CardsSectionLink>
                        <Link to={cardShowcase.link.url}>{cardShowcase.link.text}</Link>
                    </CardsSectionLink>
                </Section>
            )}

            <Section>
                <ContactUs />
            </Section>
        </Layout>
    )
}

export default SubPageOne

export const pageQuery = graphql`
    query subPageOneById($id: String!, $seoTitle: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                seo {
                    title 
                    description
                }
                title
                headingImage {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                imageShowcase {
                    hasText
                    data {
                        image {
                            relativePath
                        }
                        text {
                            title
                            body
                        }
                    }
                }
                sidePane {
                    header
                    imageItems {
                        title
                        images {
                            image {
                                relativePath
                            }
                            imgTitle
                            url
                        }
                    }
                    pillItems {
                        title
                        pills {
                            label
                            url
                        }
                    }
                    infoPairs {
                        title
                        info
                    }
                    includeRelatedBlogs
                }
                cardShowcase {
                    display
                    title
                    link {
                        text
                        url
                    }
                    cards {
                        useIcons
                        data {
                            text 
                            url 
                            image {
                                relativePath
                            }
                            bgColor
                        }
                    }
                }
            }
        }
        relatedBlogs: allMarkdownRemark(
            filter: {
                frontmatter: { templateKey: { eq: "blog-post" }, publish: { eq: true }, categories: { in: [$seoTitle] } }
            }
            sort: { fields: frontmatter___date, order: DESC }
            limit: 5
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "MMM Do, YYYY")
                    }
                }
            }
        }
    }
`