import React from "react"
import { Link } from "gatsby"
import tw, { styled } from 'twin.macro'

import Logo from "../../static/img/svg/LT3-small.svg"

const Container = styled.section`
  ${tw`
    w-full 
    mt-6
  `}
`

const Content = styled.div`
    ${tw`
        flex items-center
    `}
`

const StyledLink = styled(Link)`
    ${tw`
        text-gray-400 no-underline 
        pr-4
    `}

    :not(:first-child) {
        &::before {
            content: "\u276F";

            ${tw`
                text-gray-400 pr-3
            `}
        }
    }

    > svg {
        ${tw`
            h-4
        `}

        > path {
            fill: rgba(156, 163, 175)
        }
    }
`

const Breadcrumbs = ({ data }) => {
    function toSentenceCase(string) {
        let splitStr = string.toLowerCase().split('-');
        for (let i = 0; i < splitStr.length; i++) {
            // Only capitalize if the word is not a conjunction, and assign back to the array
            if (!["of", "and"].includes(splitStr[i]))
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        let retString = splitStr.join(' ');

        // switch statement to handle outliers
        switch (string) {
            case "ui-ux":
                retString = "UI / UX"
                break;
            case "skipthedepot":
                retString = "SkipTheDepot"
                break;
            case "recollect":
                retString = "ReCollect"
                break;
        }

        return retString;
    }
    let pathname = ""

    return (
        <Container>
            <Content>
                <StyledLink to="/">
                    <Logo />
                </StyledLink>
                {data &&
                    data.map((item, i) => {
                        pathname += item + "/"
                        if (item.length) {
                            return (
                                <StyledLink key={i} to={pathname}>
                                    {toSentenceCase(decodeURI(item))}
                                </StyledLink>
                            )
                        }
                        return null
                    })}
            </Content>
        </Container>
    )
}

export default Breadcrumbs
